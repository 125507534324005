import React from "react";
import "./upper-section.css";
import { colors } from "../../../../assets/color-scheme";
import Photo1 from "../../../../assets/images/photo2.jpg";
import Photo2 from "../../../../assets/images/photo3.jpg";
import { ReactComponent as FacebookIcn } from "../../../../assets/svg/facebook.svg";
import { ReactComponent as InstagramIcn } from "../../../../assets/svg/instagram.svg";
// import { ReactComponent as TwitterIcn } from "../../../../assets/svg/twitter.svg";

class UpperSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invertColumn: false,
    };
  }

  componentDidMount = () => {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateDimensions);
  };

  updateDimensions = () => {
    if (window.innerWidth <= 780) {
      this.setState({ invertColumn: true });
    } else this.setState({ invertColumn: false });
  };

  openLink = (link) => window.open(link, "_blank");

  renderHeader = () => {
    return (
      <div className="uw-header">
        <div className="uw-line" style={{ backgroundColor: colors.primary }} />
        <h2>
          Person-centered
          <br />
          care and support
          <br />
          with Love
        </h2>
      </div>
    );
  };

  renderSocialIcon = (icon, link) => {
    return (
      <div className="social-icon" onClick={() => this.openLink(link)}>
        {icon}
      </div>
    );
  };
  render() {
    const { invertColumn } = this.state;
    return (
      <div className="upper-wrapper">
        <div className="uw-column">
          {!invertColumn && this.renderHeader()}

          <div className="uw-img">
            <img src={Photo1} alt="Girl in front of laptop" />
          </div>

          <div className="uw-stay-connected">
            <div className="uw-social-links">
              {this.renderSocialIcon(
                <FacebookIcn width={15} height={15} fill="black" />,
                "https://www.facebook.com/Arc-en-Ciel-Support-106667401386614"
              )}
              {this.renderSocialIcon(
                <InstagramIcn width={15} height={15} fill="black" />,
                "https://www.instagram.com/arc_en_ciel_support/"
              )}
            </div>
            <p>STAY CONNECTED WITH US</p>
          </div>
        </div>

        <div className="lw-column">
          {invertColumn && this.renderHeader()}
          <div className="lw-img">
            <img src={Photo2} alt="Two girls high five" />
          </div>
          <div className="lw-paragraph-header">
            <h3>
              High quality care and support
              <br />
              <span style={{ color: colors.primary }}>
                Tailored to the needs
              </span>{" "}
              of each <br />
              individuals
            </h3>
          </div>
          <div className="lw-paragraph-body">
            <p style={{ color: colors.text.secondary }}>
              The service operates 24 hours, 7 days a week and adapts to the
              necessity of each person, which can range from low level support,
              to shared support , to 1:1 support, to 2:1 support.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default UpperSection;
