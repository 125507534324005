import React from "react";
import "./style.css";
import { ReactComponent as CloseIcn } from "../../assets/svg/close.svg";

const Menu = (props) => {
  function scroll(section) {
    const anchor = document.querySelector(section);
    anchor.scrollIntoView();
    props.toggleMenu();
  }

  return (
    <div
      className="nb-menu-wrapper flex"
      style={{ right: props.isOpen ? 0 : "-100vw" }}
    >
      <div className="nb-menu-close-btn" onClick={props.toggleMenu}>
        <CloseIcn width={20} height={20} fill="black" />
      </div>
      <div className="nb-menu-links">
        {props.links.map((link, idx) => (
          <button key={idx} onClick={() => scroll(link.redirect)}>
            {link.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Menu;
