import React from "react";
import "./style.css";
import LogoText from "../../../../assets/images/logo-text-only.png";
import { ReactComponent as LogoIcn } from "../../../../assets/svg/logo-three.svg";
import { colors } from "../../../../assets/color-scheme";
import { ReactComponent as ArrowIcn } from "../../../../assets/svg/arrow.svg";
import { ReactComponent as PhoneIcn } from "../../../../assets/svg/phone.svg";
import { ReactComponent as MobileIcn } from "../../../../assets/svg/mobile.svg";
import { ReactComponent as MailIcn } from "../../../../assets/svg/mail.svg";
import { ReactComponent as MenuIcn } from "../../../../assets/svg/menu.svg";

class TopNavigationBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayContacts: false,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.dropdown && !this.dropdown.contains(event.target)) {
      this.toggleContacts();
    }
  };

  scroll = (section) => {
    const anchor = document.querySelector(section);
    anchor.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
    setTimeout(() => {
      this.props.toggleNavBar();
    }, 1000);
  };

  renderContact = (icon, label, value) => {
    return (
      <div
        className="top-navigation-bar-contact-row"
        style={{ transform: "scale(0.8)" }}
      >
        {icon}
        <p style={{ color: colors.text.secondary }}>{label}</p>
        <h5>{value}</h5>
      </div>
    );
  };

  toggleContacts = () => {
    this.setState({ displayContacts: !this.state.displayContacts });
  };

  render() {
    const { navLinks, fixedNavBarToggled, visibleView } = this.props;
    const { displayContacts } = this.state;
    return (
      <div
        className="top-navigation-bar-wrapper box-shadow"
        style={{ top: fixedNavBarToggled ? 0 : -100 }}
      >
        <div className="top-navigation-bar">
          <div className="top-navigation-bar-logo">
            <LogoIcn width={60} height={60} />
            <img src={LogoText} alt="logo" />
          </div>

          <div className="top-navigation-bar-links">
            {navLinks.map((link, idx) => (
              <h5
                key={idx}
                style={{
                  fontWeight: link.label === visibleView ? "600" : "400",
                  color:
                    link.label === visibleView
                      ? colors.primary
                      : "rgba(0,0,0,0.4)",
                  borderBottom:
                    link.label === visibleView
                      ? `3px solid ${colors.primary}`
                      : "unset",
                }}
                className={link.label === visibleView ? "link-selected" : ""}
                onClick={() => this.scroll(link.redirect)}
              >
                {link.label}
              </h5>
            ))}
          </div>

          <div className="top-navigation-bar-contact">
            <button
              style={{ backgroundColor: colors.primary }}
              onClick={this.toggleContacts}
            >
              Our Contacts
              <span>
                <ArrowIcn width={10} height={10} fill="white" />
              </span>
            </button>
            <MenuIcn
              width={30}
              height={30}
              fill={colors.primary}
              className="menu-icon"
              onClick={this.props.toggleMenu}
            />
            {displayContacts && (
              <div
                ref={(node) => (this.dropdown = node)}
                className="top-navigation-bar-dropdown"
              >
                {this.renderContact(
                  <PhoneIcn width={15} height={15} fill={colors.primary} />,
                  "Phone:",
                  "+44 (0)20 87982512"
                )}
                {this.renderContact(
                  <MobileIcn width={15} height={15} fill={colors.primary} />,
                  "Mobile:",
                  "+44 07 429520039"
                )}
                {this.renderContact(
                  <MailIcn width={15} height={15} fill={colors.primary} />,
                  "Mail:",
                  "manager@arcencielsupport.com"
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default TopNavigationBar;
