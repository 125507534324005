import React from "react";
import "./style.css";

class Careers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isInView: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.isInViewport);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.isInViewport);
  }

  isInViewport = () => {
    const top = this.element.getBoundingClientRect().top;
    const height = this.element.offsetHeight;
    if (top * -1 > -2 && top * -1 < height) {
      if (!this.state.isInView) {
        this.props.viewOnScreen("Careers");
        this.setState({ isInView: true });
      }
    } else {
      if (this.state.isInView) {
        this.setState({ isInView: false });
      }
    }
  };

  renderJobList = (title, duration, description) => {
    return (
      <div className="career-job-description">
        <h3>{title}</h3>
        <p>
          Closing date: <span>{duration}</span>
        </p>
        <h6>{description}</h6>
      </div>
    );
  };
  render() {
    return (
      <div
        ref={(node) => (this.element = node)}
        className="career-wrapper"
        id="careers"
      >
        <div className="career">
          <div className="career-header">
            <h1>Work with us!</h1>
            <p>
              Are you looking for a job where you make a real difference? Join
              our aspiring team and create more opportunities for the people we
              support. At Arc en ciel Support, our motto is that 'Together
              Everyone Achieves More'​.
              <br /> Being part of a strong team, you'll have the satisfaction
              of knowing you've brought a positive change to someone's life. Our
              staff are highly valued and appreciated for their great input.
            </p>
          </div>

          <div className="career-table-header">
            <h4>Current vacancies:</h4>
          </div>

          <div className="career-job-list">
            {this.renderJobList(
              "Support Worker",
              "Open",
              "We are currently recruiting for Support Worker. Please contact the Manager on 02087982512 for an informal discussion."
            )}
          </div>

          <div
            style={{ width: "100%", height: 2, backgroundColor: "#979797" }}
          />
          <div className="career-footer">
            <p>
              To apply please send a copy of your CV with a covering letter to
              manager@arcencielsupport.com
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Careers;
