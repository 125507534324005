import * as firebase from "firebase";
import "firebase/firestore";

import {
  apiKey,
  authDomain,
  projectId,
  storageBucket,
  messagingSenderId,
  measurementId,
} from "./config_variables";
export const init = () => {
  // Initialize Firebase
  var config = {
    apiKey,
    authDomain,
    projectId,
    storageBucket,
    messagingSenderId,
    measurementId,
  };
  firebase.default.initializeApp(config);
  firebase.default.database();
};
