import React from "react";
import "./style.css";
import UpperSection from "./upper-section";
import LowerSection from "./lower-section";

class AboutUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isInView: false,
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.isInViewport);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.isInViewport);
  }

  isInViewport = () => {
    const top = this.element.getBoundingClientRect().top;
    const height = this.element.offsetHeight;
    if (top * -1 > -2 && top * -1 < height) {
      if (!this.state.isInView) {
        this.props.viewOnScreen("About Us");
        this.setState({ isInView: true });
      }
    } else {
      if (this.state.isInView) {
        this.setState({ isInView: false });
      }
    }
  };

  renderSocialIcon = (icon) => {
    return <div className="social-icon">{icon}</div>;
  };
  render() {
    const { toggleNavBar } = this.props;
    return (
      <div
        ref={(node) => (this.element = node)}
        className="about-us-wrapper"
        id="about-us"
      >
        <div className="about-us">
          <UpperSection />
          <LowerSection toggleNavBar={toggleNavBar} />
        </div>
      </div>
    );
  }
}

export default AboutUs;
