import React from "react";
import "./App.css";
import Menu from "./shared-component/menu";
import Home from "./screens/home";
import { init as firebaseInit } from "./config/firebase";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuIsOpen: false,
      navLinks: [
        { label: "Home", redirect: "#home" },
        { label: "About Us", redirect: "#about-us" },
        { label: "Our Services", redirect: "#services" },
        { label: "Contact Us", redirect: "#contact-us" },
        { label: "Careers", redirect: "#careers" },
      ],
    };
  }

  componentDidMount = () => {
    firebaseInit();
  };

  toggleMenu = () => {
    const { menuIsOpen } = this.state;
    this.setState({ menuIsOpen: !menuIsOpen });
  };

  render() {
    const { menuIsOpen, navLinks } = this.state;
    return (
      <div className="App">
        <Menu
          links={navLinks}
          isOpen={menuIsOpen}
          toggleMenu={this.toggleMenu}
        />
        <Home navLinks={navLinks} toggleMenu={this.toggleMenu} />
      </div>
    );
  }
}

export default App;
