import React from "react";
import "./style.css";
import Hero from "./components/hero";
import AboutUs from "./components/about-us";
import Services from "./components/services";
import ContactUs from "./components/contact-us";
import Careers from "./components/careers";
import Footer from "./components/footer";
import TopNavigationBar from "./components/top-navigation-bar";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refs: {},
      fixedNavBarToggled: false,
      visibleView: "Home",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.listenToScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }

  listenToScroll = () => {
    if (window.pageYOffset > 330) {
      if (!this.state.fixedNavBarToggled) {
        this.setState({ fixedNavBarToggled: true });
      }
    } else {
      if (this.state.fixedNavBarToggled) {
        this.setState({ fixedNavBarToggled: false });
      }
    }
  };

  setView = (view) => {
    this.setState({ visibleView: view });
  };

  toggleNavBar = () => {
    this.setState({ fixedNavBarToggled: false });
  };

  render() {
    const { fixedNavBarToggled, visibleView } = this.state;
    const { navLinks } = this.props;
    return (
      <div className="page-wrapper">
        <TopNavigationBar
          navLinks={navLinks}
          toggleMenu={this.props.toggleMenu}
          fixedNavBarToggled={fixedNavBarToggled}
          visibleView={visibleView}
          toggleNavBar={this.toggleNavBar}
        />
        <Hero
          toggleMenu={this.props.toggleMenu}
          navLinks={navLinks}
          viewOnScreen={this.setView}
          toggleNavBar={this.toggleNavBar}
        />
        <AboutUs
          setRef={this.setRef}
          viewOnScreen={this.setView}
          toggleNavBar={this.toggleNavBar}
        />
        <Services viewOnScreen={this.setView} />
        <ContactUs viewOnScreen={this.setView} />
        <Careers viewOnScreen={this.setView} />
        <Footer />
      </div>
    );
  }
}

export default Home;
