import React from "react";
import "./style.css";
import Logo from "../../../../assets/images/logo-edited.png";
import CQCLogo from "../../../../assets/images/cqc-logo.png";
import { ReactComponent as FacebookIcn } from "../../../../assets/svg/facebook-logo.svg";
import { ReactComponent as InstagramIcn } from "../../../../assets/svg/instagram.svg";
// import { ReactComponent as TwitterIcn } from "../../../../assets/svg/twitter.svg";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderSocialIcon = (icon, link) => {
    return (
      <div
        className="social-icon"
        style={{ border: "1px solid rgba(255,255,255,0.5)" }}
        onClick={() => this.openLink(link)}
      >
        {icon}
      </div>
    );
  };

  openLink = (link) => window.open(link, "_blank");

  render() {
    return (
      <div className="footer-wrapper" style={{ backgroundColor: "#0F0E0E" }}>
        <div className="footer">
          <div className="footer-logo">
            <img src={Logo} alt="company-logo" />
            <div
              style={{
                height: 100,
                width: 2,
                backgroundColor: "rgba(243, 244, 245, 0.2)",
              }}
            />
            <img src={CQCLogo} alt="cqc-logo" />
          </div>
        </div>
        <div className="footer-links">
          {this.renderSocialIcon(
            <FacebookIcn width={15} height={15} fill="rgba(255,255,255,0.5)" />,
            "https://www.facebook.com/Arc-en-Ciel-Support-106667401386614"
          )}
          {this.renderSocialIcon(
            <InstagramIcn
              width={15}
              height={15}
              fill="rgba(255,255,255,0.5)"
            />,
            "https://www.instagram.com/arc_en_ciel_support/"
          )}
        </div>
        <div className="footer-developer">
          <p
            onClick={() =>
              this.openLink(
                "https://www.linkedin.com/in/joel-ureellanah-1a54a1160/"
              )
            }
          >
            Website developed by Joel Ureellanah
          </p>
        </div>
      </div>
    );
  }
}

export default Footer;
