// export const apiKey = "AIzaSyBqRJN0RNFe_VEkdVUqLegYXagi-QedoaE";
// export const authDomain = "lvd-church-uk.firebaseapp.com";
// export const databaseURL = "https://lvd-church-uk.firebaseio.com";
// export const projectId = "lvd-church-uk";
// export const storageBucket = "lvd-church-uk.appspot.com";
// export const messagingSenderId = "986380784288";
// export const appId = "1:986380784288:web:7405a63b1311bb47";

// export const production_mode = false;

export const apiKey = "AIzaSyA6TKCxYJ-qFdAni0InZZdU2Dh6OFZ6nKM";
export const authDomain = "arc-en-ciel-support.firebaseapp.com";
export const projectId = "arc-en-ciel-support";
export const storageBucket = "arc-en-ciel-support.appspot.com";
export const messagingSenderId = "168805975721";
export const appId = "1:168805975721:web:c62cbb75d80123ce368bb8";
export const measurementId = "G-W7NY05YBMC";

export const production_mode = false;
