import React from "react";
import { colors } from "../../../../assets/color-scheme";
import "./style.css";

class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isInView: false };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.isInViewport);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.isInViewport);
  }

  isInViewport = () => {
    const top = this.element.getBoundingClientRect().top;
    const height = this.element.offsetHeight;
    if (top * -1 > -2 && top * -1 < height) {
      if (!this.state.isInView) {
        this.props.viewOnScreen("Our Services");
        this.setState({ isInView: true });
      }
    } else {
      if (this.state.isInView) {
        this.setState({ isInView: false });
      }
    }
  };

  renderCard = (iconColor, title, paragraph) => {
    return (
      <div className="services-card">
        <div className="services-card-header">
          <div
            className="services-card-icon"
            style={{ backgroundColor: iconColor }}
          >
            <div style={{ border: `4px solid ${iconColor}` }} />
          </div>
          <h3>{title}</h3>
        </div>

        <div className="services-card-body">
          <p style={{ color: "rgba(0,0,0,0.55)" }}>{paragraph}</p>
        </div>
      </div>
    );
  };
  render() {
    return (
      <div
        ref={(node) => (this.element = node)}
        className="services-wrapper"
        id="services"
        style={{ backgroundColor: colors.secondary }}
      >
        <div className="services">
          <div className="services-header">
            <h2>
              Everything we do is
              <br /> driven by <span>Love...</span>
            </h2>
            <p style={{ color: colors.text.secondary }}>
              We truly believe that everyone’s voice needs to be heard and needs
              to feel heard, hence placing them at the heart of all decision
              making in relation to their life is paramount. Our vision is a
              society where everyone is valued equally.
            </p>
          </div>

          <div className="services-body">
            <div className="services-body-header">
              <h5 style={{ color: colors.text.secondary }}>Our goals:</h5>
            </div>

            <div className="services-body-cards">
              {this.renderCard(
                "#F14108",
                "Holistic service",
                "To provide support that looks at the whole person, considering their physical, emotional, social, spiritual, intellectual and mental wellbeing."
              )}
              {this.renderCard(
                "#6BB487",
                "Living skills",
                "Support to develop and enhance daily living skills, to include personal care, household skills, safety, travel, exercise, hobbies & sports, shopping and maintaining relationships."
              )}
              {this.renderCard(
                "#FEB922",
                "Medical needs",
                "To support with medical needs, attending healthy appointments and promoting a healthy lifestyle."
              )}
              {this.renderCard(
                "#5C9CE8",
                "Social inclusion",
                "To promote social inclusion in the wider community and in a community based house which offer a full and active life as is possible in a supportive environment."
              )}
              {this.renderCard(
                "#39CACA",
                "Fullfilled life",
                "To encourage and support them to build their social networks and work toward personal goals such as learning to look after their home, have access to education, social & leisure activities or getting into employment."
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
