import React from "react";
import "./style.css";
import { ReactComponent as PhoneIcn } from "../../../../assets/svg/phone.svg";
import { ReactComponent as MobileIcn } from "../../../../assets/svg/mobile.svg";
import { ReactComponent as MailIcn } from "../../../../assets/svg/mail.svg";
import { ReactComponent as MenuIcn } from "../../../../assets/svg/menu.svg";
import Logo from "../../../../assets/images/logo.png";
import Photo from "../../../../assets/images/photo1.jpg";
import { colors } from "../../../../assets/color-scheme";

class Hero extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spirals: [200, 300, 400, 500],
      isInView: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.isInViewport);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.isInViewport);
  }

  isInViewport = () => {
    const top = this.element.getBoundingClientRect().top;
    const height = this.element.offsetHeight;
    if (top * -1 > -2 && top * -1 < height) {
      if (!this.state.isInView) {
        this.props.viewOnScreen("Home");
        this.setState({ isInView: true });
      }
    } else {
      if (this.state.isInView) {
        this.setState({ isInView: false });
      }
    }
  };

  renderContact = (icon, label, value) => {
    return (
      <div className="header-contact">
        {icon}
        <p style={{ color: colors.text.secondary }}>{label}</p>
        <h5>{value}</h5>
      </div>
    );
  };

  scroll = (section) => {
    const anchor = document.querySelector(section);
    anchor.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
    setTimeout(() => {
      this.props.toggleNavBar();
    }, 1000);
  };

  render() {
    const { spirals } = this.state;
    const { navLinks } = this.props;
    return (
      <div
        ref={(node) => (this.element = node)}
        className="hero"
        id="home"
        style={{ backgroundColor: colors.secondary }}
      >
        {/* SPIRALS DESIGN */}
        <div className="spirals">
          {spirals.map((spiral, idx) => (
            <div
              key={idx}
              className="spiral"
              style={{
                width: spiral,
                height: spiral,
                top: -spiral / 2,
                right: -spiral / 2,
                border: `4px solid ${colors.primary}`,
              }}
            />
          ))}
        </div>
        {/* HEADER */}
        <div className="header">
          <div className="header-logo">
            <img src={Logo} alt="Logo" />
          </div>
          <div className="header-info">
            <MenuIcn
              width={30}
              height={30}
              fill={colors.primary}
              className="menu-icon"
              onClick={this.props.toggleMenu}
            />
            {this.renderContact(
              <PhoneIcn width={15} height={15} fill={colors.primary} />,
              "Phone:",
              "+44 (0)20 87982512"
            )}
            {this.renderContact(
              <MobileIcn width={15} height={15} fill={colors.primary} />,
              "Mobile:",
              "+44 07 429520039"
            )}
            {this.renderContact(
              <MailIcn width={15} height={15} fill={colors.primary} />,
              "Mail:",
              "manager@arcencielsupport.com"
            )}
          </div>
        </div>

        {/* NAVIGATION LINKS */}
        <div className="nav-links" style={{ zIndex: 1 }}>
          {navLinks.map((link, idx) => (
            <h5
              key={idx}
              style={{
                color:
                  link.label === "Home" ? colors.primary : "rgba(0,0,0,0.4)",
                borderBottom:
                  link.label === "Home"
                    ? `3px solid ${colors.primary}`
                    : "unset",
              }}
              className={link.label === "Home" ? "link-selected" : ""}
              onClick={() => this.scroll(link.redirect)}
            >
              {link.label}
            </h5>
          ))}
        </div>

        {/* BANNER */}
        <div className="banner">
          <div className="banner-left">
            <h1>
              Extraordinary, simple <br />
              caregiving
            </h1>
            <p style={{ color: colors.text.secondary }}>
              At Arcencielsupport we provide high quality personalised care and
              support for a diverse range of service users, specifically for
              Adults with learning disabilities, autism and complex needs to
              include mental health needs, associated health needs and behaviour
              that may challenge. We aim to enhance, enable and promote
              community living for everyone using our service.
            </p>
            <button
              style={{ backgroundColor: colors.primary }}
              onClick={() => this.scroll("#contact-us")}
            >
              Contact Us
            </button>
          </div>

          <div className="banner-right">
            <img src={Photo} alt="Two man smiling" />
          </div>
        </div>
      </div>
    );
  }
}

export default Hero;
