import React from "react";
import "./lower-section.css";
import DiningRoom from "../../../../assets/images/dining-room.jpeg";
import LivingRoom2 from "../../../../assets/images/living-room-2.jpeg";
import Kitchen from "../../../../assets/images/kitchen.jpeg";
import EnSuiteBedroom from "../../../../assets/images/en-suite-bedroom.jpeg";
import Entrance from "../../../../assets/images/entrance.jpeg";
import { colors } from "../../../../assets/color-scheme";
import { ReactComponent as ArrowIcn } from "../../../../assets/svg/arrow.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { ReactComponent as CloseIcn } from "../../../../assets/svg/close.svg";

class LowerSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showGalleryBtn: false,
      modalIsOpen: false,
      initialSlide: 1,
      images: [
        { source: DiningRoom },
        { source: LivingRoom2 },
        { source: Kitchen },
        { source: EnSuiteBedroom },
        { source: Entrance },
      ],
    };
  }

  componentDidMount = () => {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateDimensions);
  };

  updateDimensions = () => {
    if (window.innerWidth <= 970) {
      this.setState({ showGalleryBtn: true });
    } else this.setState({ showGalleryBtn: false });
  };

  toggleModal = (blockScroll = false) => {
    this.setState((state) => ({ modalIsOpen: !state.modalIsOpen }));
    if (blockScroll) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  openGallery = async (initialSlide) => {
    await this.setState({ initialSlide });
    this.toggleModal(true);
  };

  render() {
    const { showGalleryBtn, modalIsOpen, images, initialSlide } = this.state;
    return (
      <div className="lower-section-wrapper">
        <div className="lsu-wrapper">
          <div className="lsu-left">
            <h3>
              <span style={{ color: colors.primary }}>
                Housing with Support
              </span>{" "}
              to empower <br />
              and achieve independence
            </h3>
            <p style={{ color: colors.text.secondary }}>
              We can provide <span>Supported Living</span> to individuals in or
              around London. Our service offers a unique fusion of care, support
              and accommodation, allowing people with learning disabilities,
              autism and complex needs to have greater level of autonomy,
              empowerment and achievement through having their own tenancy and
              to have utmost independence, choice and control over their lives.
            </p>
          </div>

          <div className="lsu-right">
            <div
              className="lsu-right-absolute"
              style={{ backgroundColor: colors.ternary }}
            />
            <div
              className="lsu-right-img-wrapper"
              style={{ backgroundColor: colors.ternary }}
            >
              <img
                src={DiningRoom}
                alt="dining room"
                onClick={() => this.openGallery(0)}
              />
              {!showGalleryBtn && (
                <img
                  src={LivingRoom2}
                  onClick={() => this.openGallery(1)}
                  alt="living room"
                />
              )}
            </div>
          </div>
        </div>

        <div className="lsl-wrapper">
          <div
            className="lsl-absolute"
            style={{ backgroundColor: colors.ternary }}
          />
          <div
            className="lsl-img-wrapper"
            style={{ backgroundColor: colors.ternary }}
          >
            <img
              src={Kitchen}
              alt="kitchen"
              onClick={() => this.openGallery(2)}
            />
            <img
              src={EnSuiteBedroom}
              alt="en suite bedroom"
              onClick={() => this.openGallery(3)}
            />
            {!showGalleryBtn && (
              <img
                src={Entrance}
                alt="entrance"
                onClick={() => this.openGallery(4)}
              />
            )}
            {showGalleryBtn && (
              <button onClick={() => this.openGallery(0)}>
                See Gallery
                <span>
                  <ArrowIcn width={15} height={15} fill="black" />
                </span>
              </button>
            )}
          </div>
        </div>
        {modalIsOpen && (
          <div className="carousel-modal" onClick={() => this.toggleModal()}>
            <div className="carousel-modal-close">
              <CloseIcn width={15} height={15} fill="white" />
            </div>
            <div
              className="carousel-modal-content"
              onClick={(e) => e.stopPropagation()}
            >
              <Carousel selectedItem={initialSlide} showIndicators={false}>
                {images.map(({ source }, idx) => (
                  <div key={idx} className="carousel-img">
                    <img src={source} alt="something" />
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default LowerSection;
