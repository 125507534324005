import React from "react";
import { colors } from "../../../../assets/color-scheme";
import "./style.css";
import ContactImg from "../../../../assets/images/contact.jpg";
import { ReactComponent as CheckIcn } from "../../../../assets/svg/check.svg";
import firebase from "firebase/app";

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
      fullNameError: false,
      email: "",
      emailError: false,
      phone: "",
      message: "",
      messageError: false,
      errorMessage: "",
      sendBtnHovered: false,
      formSubmited: false,
      formSubmitedError: false,
      loading: false,
      isInView: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.isInViewport);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.isInViewport);
  }

  isInViewport = () => {
    const top = this.element.getBoundingClientRect().top;
    const height = this.element.offsetHeight;
    if (top * -1 > -2 && top * -1 < height) {
      if (!this.state.isInView) {
        this.props.viewOnScreen("Contact Us");
        this.setState({ isInView: true });
      }
    } else {
      if (this.state.isInView) {
        this.setState({ isInView: false });
      }
    }
  };

  isValidForm = () => {
    const { fullName, email, message } = this.state;
    let isValidated = true;
    if (fullName === undefined || fullName === null || fullName.length === 0) {
      this.setState({
        fullNameError: true,
        errorMessage: "Please complete all required fields.",
      });
      isValidated = false;
    }
    if (email === undefined || email === null || email.length === 0) {
      this.setState({
        emailError: true,
        errorMessage: "Please complete all required fields.",
      });
      isValidated = false;
    }
    if (message === undefined || message === null || message.length === 0) {
      this.setState({
        messageError: true,
        errorMessage: "Please complete all required fields.",
      });
      isValidated = false;
    }
    return isValidated;
  };

  submitForm = async () => {
    const { fullName, email, phone, message } = this.state;
    if (this.isValidForm()) {
      await this.setState({ loading: true });
      let phn = phone.length > 0 ? phone : "Not given";
      let response = await this.sendMail(fullName, email, phn, message);
      if (response.code === 200) {
        this.setState({ formSubmited: true });
      } else {
        this.setState({ formSubmitedError: true });
      }
    }
    this.setState({ loading: false });
  };

  sendMail = (fullName, email, phone, message) => {
    // firebase.functions().useEmulator("localhost", "5000"); //for development only
    let service = "gmail";
    let auth = {
      user: "arcencielwebsite@gmail.com",
      pass: "Arc3nci3l5upp0rt!",
    };
    let from = "Enquiry <arcencielwebsite@gmail.com>";
    let to = "manager@arcencielsupport.com";
    let subject = `Enquiry from: ${fullName}`;
    let html = `
    <p style="font-size: 16px;">Full name: <b>${fullName}</b></p>
    <p style="font-size: 16px;">Email address: <b>${email}</b></p>
    <p style="font-size: 16px;">Phone: <b>${phone}</b></p>
    <p style="font-size: 16px;">Enquiry: ${message}</p>
    `;
    let sendMail = firebase.functions().httpsCallable("sendMailArc");
    return sendMail({ service, auth, from, to, subject, html })
      .then((response) => {
        try {
          if (response.data.accepted.length > 0) {
            return { code: 200, message: "Enquiry sent successfully." };
          } else {
            return { code: 503, message: "Enquiry could not be sent" };
          }
        } catch (ex) {
          return { code: 503, message: "Enquiry could not be sent" };
        }
      })
      .catch((err) => {
        return { code: 503, message: "Enquiry could not be sent" };
      });
  };

  resetForm = () => {
    this.setState({
      fullName: "",
      fullNameError: false,
      email: "",
      emailError: false,
      phone: "",
      message: "",
      messageError: false,
      errorMessage: "",
      sendBtnHovered: false,
      formSubmited: false,
      formSubmitedError: false,
      loading: false,
      isInView: false,
    });
  };

  renderInput = (label, state, error, required, textarea = false) => {
    return (
      <div
        className="contact-us-input-text"
        style={{ height: textarea ? 180 : 110 }}
      >
        <label>
          {label}
          {required && <span>*</span>}
        </label>
        {!textarea && (
          <input
            style={{ border: error ? "2px solid red" : "1px solid #b2b5c7" }}
            name={state}
            placeholder=""
            value={this.state[state]}
            onChange={(e) => this.setState({ [state]: e.target.value })}
          />
        )}
        {textarea && (
          <textarea
            style={{ border: error ? "2px solid red" : "1px solid #b2b5c7" }}
            name={state}
            placeholder=""
            value={this.state[state]}
            onChange={(e) => this.setState({ [state]: e.target.value })}
          />
        )}
      </div>
    );
  };
  render() {
    const {
      sendBtnHovered,
      fullNameError,
      emailError,
      errorMessage,
      formSubmited,
      formSubmitedError,
      loading,
    } = this.state;
    return (
      <div
        ref={(node) => (this.element = node)}
        className="contact-us-wrapper"
        id="contact-us"
      >
        <div className="contact-us">
          <div className="contact-us-left">
            {loading ? (
              <div className="contact-us-loading">
                <p>Submiting form, please wait...</p>
              </div>
            ) : formSubmited ? (
              formSubmitedError ? (
                <div className="form-submitted">
                  <CheckIcn width={60} height={60} fill="green" />
                  <h3>Enquiry could not be sent</h3>
                  <p style={{ color: colors.text.secondary }}>
                    There was a problem while sending your enquiry. Please call
                    +44 (0)20 87982512 and we'll be able to assist you with any
                    query youu might have.
                  </p>
                </div>
              ) : (
                <div className="form-submitted">
                  <CheckIcn width={60} height={60} fill="green" />
                  <h3>Form Submited!</h3>
                  <p style={{ color: colors.text.secondary }}>
                    Thank you for sending us your enquiry. We will be in touch
                    as soon as we can.
                  </p>
                  <button
                    style={{
                      backgroundColor: colors.primary,
                    }}
                    onClick={() => this.resetForm()}
                  >
                    Send another enquiry
                  </button>
                </div>
              )
            ) : (
              <React.Fragment>
                <p className="info">Please complete all required fields.</p>
                {this.renderInput("Full Name", "fullName", fullNameError, true)}
                {this.renderInput("Email", "email", emailError, true)}
                {this.renderInput("Phone", "phone", false, false)}
                {this.renderInput(
                  "Your message",
                  "message",
                  errorMessage,
                  true,
                  true
                )}
                {errorMessage.length > 0 && (
                  <div className="contact-us-form-error-msg">
                    <h6>{errorMessage}</h6>
                  </div>
                )}
                <p className="data-protection-info">
                  This information will be stored and processed for the purpose
                  of this enquiry and will not be shared or transferred without
                  consent.
                </p>
                <button
                  style={{
                    backgroundColor: sendBtnHovered ? colors.primary : "white",
                    border: `3px solid ${colors.primary}`,
                    color: sendBtnHovered ? "white" : colors.primary,
                  }}
                  onMouseEnter={() => this.setState({ sendBtnHovered: true })}
                  onMouseLeave={() => this.setState({ sendBtnHovered: false })}
                  onClick={this.submitForm}
                >
                  Send
                </button>
              </React.Fragment>
            )}
          </div>

          <div className="contact-us-right">
            <div
              className="contact-us-upper"
              style={{ backgroundColor: colors.ternary }}
            >
              <h4>LET'S GET IN TOUCH</h4>
              <p>
                Fill up the form with your enquiry or call our helpline at:{" "}
                <span>+44 (0)20 87982512</span>
              </p>
            </div>

            <div className="contact-us-lower">
              <img src={ContactImg} alt="contact-us" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUs;
